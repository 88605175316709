'use client'

import { useActionState } from 'react'
import { deleteBookmarkAction } from '@/data/actions/bookmark-actions'

import { DeleteButton } from '@/components/custom/delete-button'
import { StrapiErrors } from '@/components/custom/strapi-errors'

const INITIAL_STATE = {
  strapiErrors: null,
  data: null,
  message: null,
}

export default function DeleteBookmarkForm({ id }) {
  const deleteBookmarkId = deleteBookmarkAction.bind(null, id)
  const [deleteState, deleteAction] = useActionState(deleteBookmarkId, INITIAL_STATE)

  return (
    <form action={deleteAction}>
      <DeleteButton className="bg-red-700 hover:bg-red-600" />
      <StrapiErrors error={deleteState?.strapiErrors} />
    </form>
  )
}
