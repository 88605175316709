'use client'

import { usePagination } from '@/hooks'

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'

export const ListPagination = ({ currentPage, totalPages }) => {
  const { numbers, prevLink, nextLink, pageLink } = usePagination({
    currentPage,
    totalPages: totalPages > 500 ? 500 : totalPages,
  })

  return (
    <Pagination className="mt-5">
      <PaginationContent>
        {currentPage !== 1 && (
          <PaginationItem className="text-teal-50">
            <PaginationPrevious
              className="hidden bg-gray-100 text-teal-800 md:flex"
              href={prevLink}
            />
          </PaginationItem>
        )}

        {totalPages > 1 &&
          numbers.map((number) =>
            number === 'ellipsis1' || number === 'ellipsis2' ? (
              <PaginationEllipsis className="hidden text-teal-800 md:flex" key={number} />
            ) : (
              <PaginationItem key={number}>
                <PaginationLink
                  isActive={number === currentPage}
                  href={pageLink(number)}
                  className="bg-red-100 border-red-100 text-teal-800"
                >
                  {number}
                </PaginationLink>
              </PaginationItem>
            )
          )}

        {currentPage !== totalPages && (
          <PaginationItem>
            <PaginationNext className="hidden bg-gray-100 text-teal-800 md:flex" href={nextLink} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  )
}
