'use client'

import { Loader2, TrashIcon } from 'lucide-react'
import { useFormStatus } from 'react-dom'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'

function Loader() {
  return (
    <div className="flex items-center">
      <Loader2 className="h-4 w-4 animate-spin" />
    </div>
  )
}

export function DeleteButton({ className }) {
  const status = useFormStatus()
  return (
    <Button
      type="submit"
      aria-disabled={status.pending}
      disabled={status.pending}
      size="xs"
      //className={cn(className)}
      variant="ghost"
    >
      {status.pending ? <Loader /> : <TrashIcon className="w-4 h-4" />}
    </Button>
  )
}
